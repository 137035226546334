<template>
  <div>
    <locations />
    <b-form @submit="onSubmit">
      <div class="page wrap-1200">
        <p class="info">
          {{ $t("text.support.inquiry") }}
        </p>
        <div class="row g-0 table-type header-col">
          <div class="col-sm-4">
            <span class="required">{{ $t("title.common.title") }}</span>
          </div>
          <div class="col-sm-8">
            <input type="text" name="title" v-model="form.title" />
            <p class="mt-2 text-subscript">
              {{ $t("text.support.inquiry_sub1") }}
            </p>
          </div>

          <div class="col-sm-4">
            <span class="required">{{ $t("title.support.first_name") }}</span>
          </div>
          <div class="col-sm-8">
            <input type="text" name="firstname" v-model="form.firstname" />
            <p class="mt-2 text-subscript">
              {{ $t("text.support.inquiry_sub2") }}
            </p>
          </div>

          <div class="col-sm-4">
            <span class="required">{{ $t("title.support.last_name") }}</span>
          </div>
          <div class="col-sm-8">
            <input type="text" name="lastname" v-model="form.lastname" />
            <p class="mt-2 text-subscript">
              {{ $t("text.support.inquiry_sub2") }}
            </p>
          </div>

          <div class="col-sm-4">
            <span class="required">{{ $t("title.common.phone") }}</span>
          </div>
          <div class="col-sm-8">
            <input type="text" name="phone" v-model="form.phone" />
            <p class="mt-2 text-subscript">
              {{ $t("text.support.inquiry_sub3") }}
            </p>
          </div>

          <!-- <div class="col-sm-4">
            <span class="required">{{ $t("title.support.country") }}</span>
          </div>
          <div class="col-sm-8">
            <select name="country" v-model="form.country">
              <option selected value="">Country</option>
              <option
                :value="country.countryno"
                v-for="(country, index) in countrylist"
                :key="index"
              >
                {{ country.countryname }}
              </option>
            </select>
          </div>

          <div class="col-sm-4">
            <span class="required">{{ $t("title.common.region") }}</span>
          </div>
          <div class="col-sm-8">
            <select name="state" v-model="form.state">
              <option :selected="form.state === ''" value="">
                City / State
              </option>
              <option
                :value="states.statesname"
                v-for="(states, index) in stateslist"
                :key="index"
              >
                {{ states.statesdesc }}
              </option>
            </select>
          </div> -->

          <div class="col-sm-4">
            <span class="required">{{ $t("title.common.email") }}</span>
          </div>
          <div class="col-sm-8">
            <input type="text" name="email" v-model="form.email" />
            <p class="mt-2 text-subscript">
              {{ $t("text.support.inquiry_sub4") }}
            </p>
          </div>

          <div class="col-sm-4">
            <span class="required">{{
              $t("title.support.inquiry_content")
            }}</span>
          </div>
          <div class="col-sm-8">
            <textarea
              name="contents"
              id=""
              rows="7"
              v-model="form.contents"
            ></textarea>
          </div>
        </div>
        <!-- table-type -->

        <div class="agree-box">
          <div class="justify-content-center justify-content-sm-start mt-4">
            <vue-recaptcha
              :sitekey="RECAPTCHA_SITEKEY"
              :loadRecaptchaScript="true"
              @verify="verify"
            ></vue-recaptcha>
          </div>
        </div>

        <div class="btn-bottom-wrapper">
          <b-button
            type="submit"
            size="xl"
            variant="primary"
            aria-label="register"
          >
            {{ $t("button.common.write") }}
          </b-button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import locations from "@/components/Location";
import ax from "@/api/support";
import "@/filter/common";
import VueRecaptcha from "vue-recaptcha";
import _ from "lodash";

export default {
  name: "Inquiry",
  components: {
    locations,
    VueRecaptcha,
  },
  data() {
    return {
      form: {
        recaptcha_yn: "n",
        recaptcha_token: "",
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        country: "",
        state: "",
        contents: "",
        title: "",
      },
      countrylist: [],
      stateslist: [],
      RECAPTCHA_SITEKEY: process.env.VUE_APP_RECAPTCHA_SITEKEY,
    };
  },
  methods: {
    verify(response) {
      this.form.recaptcha_token = response;
      this.form.recaptcha_yn = "y";
    },
    onSubmit(event) {
      event.preventDefault();

      if (this.form.title === "") {
        alert(
          this.$i18n.t("alert.common.field_enter", {
            field: this.$i18n.t("title.common.title"),
          })
        );

        return false;
      }

      if (this.form.firstname === "") {
        alert(
          this.$i18n.t("alert.common.field_enter", {
            field: this.$i18n.t("title.support.first_name"),
          })
        );

        return false;
      }

      if (this.form.lastname === "") {
        alert(
          this.$i18n.t("alert.common.field_enter", {
            field: this.$i18n.t("title.support.last_name"),
          })
        );

        return false;
      }

      if (this.form.phone === "") {
        alert(
          this.$i18n.t("alert.common.field_enter", {
            field: this.$i18n.t("title.common.phone"),
          })
        );

        return false;
      }

      if (this.form.email === "") {
        alert(
          this.$i18n.t("alert.common.field_enter", {
            field: this.$i18n.t("title.common.email"),
          })
        );

        return false;
      }

      if (this.form.contents === "") {
        alert(
          this.$i18n.t("alert.common.field_enter", {
            field: this.$i18n.t("title.common.contents"),
          })
        );

        return false;
      }

      if (this.form.recaptcha_yn === "n") {
        alert(this.$i18n.t("alert.common.recaptcha"));
        return false;
      }

      ax.set_inquirytwrite(this.form, (flag, code, message) => {
        if (flag) {
          if (code === 200) {
            alert(message);
            //this.resetForm()
            location.reload();
          }
        } else {
          alert(message);
          return false;
        }
      });
    },
    resetForm() {
      this.form = _.cloneDeep(this.form);
      this.form.firstname = "";
      this.form.lastname = "";
      this.form.phone = "";
      this.form.email = "";
      this.form.country = "";
      this.form.state = "";
      this.form.contents = "";
      this.form.title = "";
    },
    get_countrylist() {
      ax.get_countrylist((flag, data) => {
        if (flag) {
          this.countrylist = data.countrylist;
        }
      });
    },
    get_stateslist() {
      ax.get_stateslist(this.form.country, (flag, data) => {
        if (flag) {
          this.stateslist = data.stateslist;
        }
      });
    },
  },
  created() {
    this.get_countrylist();
  },
  watch: {
    "form.country": function () {
      this.form.state = "";
      if (this.form.country != "") this.get_stateslist();
      else this.stateslist = [];
    },
  },
};
</script>
