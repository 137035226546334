<template>
  <div>
    <location />
    <div class="page">
      <account-tab />
      <div class="tab-content wrap-1200" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-1"
          role="tabpanel"
          aria-labelledby="pills-1-tab"
        >
          <div
            class="filter-group row row-cols-1 row-cols-md-2 g-0 justify-content-md-between"
          >
            <div class="col">
              <h6 class="fs-6 fs-lg-18">
                {{ $t("text.membership.favorite_location") }}
              </h6>
            </div>

            <b-form @submit="onSubmit" class="col mt-3 mt-md-0">
              <div class="row row-cols-1 row-cols-sm-2 g-2 justify-content-end">
                <div class="col">
                  <select
                    aria-label="Select one"
                    name="searchtype"
                    v-model="searchData.searchtype"
                  >
                    <option value="1">{{ $t("title.common.address") }}</option>
                    <option value="2">
                      {{ $t("filter.common.location") }}
                    </option>
                  </select>
                </div>
                <div class="col">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      name="searchname"
                      v-model="searchData.searchname"
                    />
                    <button
                      class="btn btn-primary btn-md icon-search"
                      type="submit"
                    ></button>
                  </div>
                </div>
              </div>
            </b-form>
          </div>

          <table class="table board">
            <thead>
              <tr>
                <!-- <th class="col-auto d-lg-table-cell">
                  {{ $t("title.common.region") }}
                </th> -->
                <th class="col-auto d-lg-table-cell text-center">
                  {{ $t("title.common.location") }}
                </th>
                <th class="col-auto d-none d-lg-table-cell">
                  {{ $t("title.common.phone") }}
                </th>
                <th class="col-auto d-none d-lg-table-cell">
                  {{ $t("title.common.address") }}
                </th>
                <th class="col-auto d-none d-lg-table-cell">
                  {{ $t("title.common.date") }}
                </th>
                <th class="col-auto d-none d-lg-table-cell">
                  {{ $t("title.common.choose") }}
                </th>
                <th class="th-arrow">&nbsp;</th>
              </tr>
            </thead>
            <tbody v-if="data_yn">
              <template v-for="(item, index) in items">
                <tr :key="index">
                  <!-- <td class="col-auto d-lg-table-cell">{{ item.area }}</td> -->
                  <td class="col-auto d-lg-table-cell shop-name">
                    <router-link
                      :to="{
                        name: 'FavoriteLocationInfo',
                        params: { shopno: item.shopno },
                        query: {
                          searchtype: searchData.searchtype,
                          searchname: searchData.searchname,
                        },
                      }"
                      class="more"
                    >
                      {{ item.shopname }}
                    </router-link>
                  </td>
                  <td class="col-auto d-none d-lg-table-cell">
                    {{ item.phone }}
                  </td>
                  <td class="col-auto d-none d-lg-table-cell">
                    {{ item.address1 }}
                  </td>
                  <td
                    class="col-auto d-none d-lg-table-cell text-date"
                    v-if="item.shopno === shopInfo.owner_fk"
                  >
                    <!-- 서비스기준 -->
                    {{ shopInfo.timezone_regdate.timezone_common | dateformat }}
                  </td>
                  <td v-else class="col-auto d-none d-lg-table-cell"></td>
                  <td class="col-auto d-none d-lg-table-cell">
                    <input
                      type="checkbox"
                      class="form-check-input me-0"
                      :checked="item.shopno === shopInfo.owner_fk"
                      @click="shop_choose(item.shopno)"
                    />
                  </td>
                  <td class="td-arrow" @click="toggle_tr(index)">
                    <img
                      src="/img/arrow_none_collapsed.png"
                      :class="{ 'btn-toggle-arrow': view_hide === index }"
                    />
                  </td>
                </tr>
                <tr
                  class="d-lg-none"
                  :class="{ show: view_hide === index }"
                  :key="index + 'r'"
                  v-if="view_hide === index"
                >
                  <td colspan="6">
                    <div class="toggle-content">
                      <div class="row">
                        <div class="col-4">{{ $t("title.common.phone") }}</div>
                        <div class="col-8">{{ item.phone }}</div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          {{ $t("title.common.address") }}
                        </div>
                        <div class="col-8">{{ item.address }}</div>
                      </div>
                      <div class="row">
                        <div class="col-4">{{ $t("title.common.date") }}</div>
                        <div
                          class="col-8 text-date"
                          v-if="item.shopno === shopInfo.owner_fk"
                        >
                          {{
                            shopInfo.timezone_regdate.timezone_common
                              | dateformat
                          }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4">{{ $t("title.common.choose") }}</div>
                        <div class="col-8">
                          <input
                            type="checkbox"
                            class="form-check-input me-0"
                            :checked="item.shopno === shopInfo.owner_fk"
                            @click="shop_choose(item.shopno)"
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="6">{{ $t("text.common.no_data") }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <b-pagination-nav
          :link-gen="linkGen"
          :number-of-pages="page_total"
          v-model="searchData.pageno"
          use-router
          aria-label="Page navigation"
          v-if="page_total > 1"
          first-class="prev"
          prev-class="prev"
          next-class="next"
          last-class="next"
          hide-ellipsis
          limit="10"
        >
          <template #first-text>
            <i class="material-icons">keyboard_double_arrow_left</i>
          </template>
          <template #prev-text>
            <i class="material-icons">navigate_before</i>
          </template>
          <template #next-text>
            <i class="material-icons">navigate_next</i>
          </template>
          <template #last-text>
            <i class="material-icons">keyboard_double_arrow_right</i>
          </template>
          <template #page="{ page, active }">
            <a v-if="active">{{ page }}</a>
            <a v-else>{{ page }}</a>
          </template>
        </b-pagination-nav>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import AccountTab from "@/components/membership/AccountTab";
import ax from "@/api/membership";

export default {
  name: "FavoriteLocation",
  components: {
    location,
    AccountTab,
  },
  data() {
    return {
      searchData: {
        pageno: parseInt(this.$route.query.page) || 1,
        searchtype:
          this.$route.query.searchtype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchtype) ||
          1,
        searchname:
          this.$route.query.searchname ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchname) ||
          "",
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],
      shopInfo: {},
      view_hide: null,
      change_flag: null,
      flag_cnt: 0,
      flags: [],
    };
  },
  watch: {
    "searchData.searchtype": function () {
      this.searchData.pageno = 1;
      this.get_memberprefershop();
    },
    "searchData.searchname": function () {
      this.searchData.pageno = 1;
      this.get_memberprefershop();
    },

    "searchData.pageno": function () {
      this.get_memberprefershop();
    },
  },
  methods: {
    get_memberprefershop() {
      this.view_hide = null;
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_memberprefershop(this.searchData, (flag, data) => {
        if (flag) {
          if (data.shoplistcnt <= 0) {
            this.data_yn = false;
            return false;
          } else {
            this.data_yn = true;
          }
          this.total_cnt = data.shoplistcnt;
          this.page_total = Math.ceil(data.shoplistcnt / this.rows);
          this.items = data.shoplist;
          this.shopInfo = data.shopinfo || [];
          this.flag_cnt = 0;
          this.flags = [];
          // for (let i = 0; i < this.shopInfo.length; i++) {
          //   if (this.items[i].flag === "1") this.flag_cnt += 1;
          //   this.flags.push(this.items[i].flag);
          // }
        } else {
          this.page_total = 0;
          this.data_yn = false;
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router.push({
        query: {
          searchtype: this.searchData.searchtype,
          searchname: this.searchData.searchname,
        },
      });
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
    shop_choose(shopno) {
      event.preventDefault();
      this.change_flag = this.shopInfo.owner_fk == shopno ? true : false;
      if (this.change_flag && this.shopInfo.owner_fk) {
        alert(this.$i18n.t("alert.membership.same_location"));
        this.get_memberprefershop();
        return false;
      }
      if (
        confirm(this.$i18n.t("alert.membership.location_resets_location_point"))
      ) {
        ax.memberprefershopchoose(shopno, 1, (flag, data) => {
          alert(data);
          this.get_memberprefershop();
        });
      }
    },
  },
  created() {
    this.get_memberprefershop();
  },
};
</script>
