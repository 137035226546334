<template>
  <div>
    <location />
    <b-form @submit="onSubmit">
      <div class="page wrap-1200">
        <p class="info mb-4 mb-lg-5" v-html="$t('text.main.sign_up')"></p>
        <h6 class="sub-title">{{ $t("text.common.required") }}</h6>
        <div class="row g-0 table-type header-col">
          <div class="col-sm-4">
            <span class="required">{{ $t("title.common.id") }}</span>
          </div>
          <div class="col-sm-8">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                name="id"
                id="id"
                :placeholder="
                  $t('text.common.field_enter', {
                    field: $t('text.common.id'),
                  })
                "
                :aria-label="
                  $t('text.common.field_enter', {
                    field: $t('title.common.id'),
                  })
                "
                v-model="form.id"
                required
              />
              <button
                class="btn btn-primary btn-md icon-check"
                type="button"
                @click="availability_check(1, form.id)"
              >
                {{ $t("button.common.availability_check") }}
              </button>
            </div>
            <p class="mt-2 text-subscript">
              {{ $t("text.main.sign_up1") }}
            </p>
          </div>

          <div class="col-sm-4">
            <span class="required">{{ $t("title.membership.password") }}</span>
          </div>
          <div class="col-sm-8">
            <input
              type="password"
              name="password"
              id="password"
              v-model="form.password"
              required
            />
            <p class="mt-2 text-subscript">
              {{ $t("text.main.sign_up2") }}
            </p>
          </div>

          <div class="col-sm-4">
            <span class="required">{{
              $t("title.membership.confirm_password")
            }}</span>
          </div>
          <div class="col-sm-8">
            <input
              type="password"
              name="password_re"
              id="password_re"
              v-model="form.password_re"
              required
            />
          </div>

          <div class="col-sm-4">
            <span class="required">{{ $t("title.common.handphone") }}</span>
          </div>
          <div class="col-sm-8">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                :placeholder="
                  $t('text.common.field_enter', {
                    field: $t('title.common.handphone'),
                  })
                "
                :aria-label="
                  $t('text.common.field_enter', {
                    field: $t('title.common.handphone'),
                  })
                "
                v-model="form.handphone"
                required
              />
              <button
                class="btn btn-primary btn-md icon-check"
                type="button"
                @click="sms_send()"
              >
                {{ $t("text.membership.send_auth_text") }}
              </button>
            </div>
            <!-- <p class="mt-2 text-subscript mb-2">
              ex) 010-1234-5678, 01012345678
            </p> -->
            <template v-if="check_form.authchk === true">
              <div>
                <div class="input-group mt-2">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="
                      $t('text.common.field_enter', {
                        field: $t('title.membership.auth_number'),
                      })
                    "
                    :aria-label="
                      $t('text.common.field_enter', {
                        field: $t('title.membership.auth_number'),
                      })
                    "
                    v-model="form.auth_number"
                    required
                  />
                  <button
                    class="btn btn-primary btn-md icon-check"
                    type="button"
                    @click="auth_number_chk()"
                  >
                    {{ $t("button.common.confirm") }}
                  </button>
                </div>
              </div>
            </template>
          </div>

          <div class="col-sm-4">
            <span class="required">{{ $t("title.common.nickname") }}</span>
          </div>
          <div class="col-sm-8">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                v-model="form.nickname"
                required
              />
              <button
                class="btn btn-primary btn-md icon-check"
                type="button"
                @click="availability_check(2, form.nickname)"
              >
                {{ $t("button.common.availability_check") }}
              </button>
            </div>
            <p class="mt-2 text-subscript">
              {{ $t("text.main.sign_up4") }}
            </p>
          </div>

          <div class="col-sm-4">
            <span class="required">{{ $t("title.common.gender") }}</span>
          </div>
          <div class="col-sm-8">
            <div class="form-check d-inline-block me-3">
              <input
                class="form-check-input"
                type="radio"
                value="M"
                id="Male"
                name="gender"
                checked
                v-model="form.gender"
              />
              <label class="form-check-label" for="Male">{{
                $t("title.common.male")
              }}</label>
            </div>
            <div class="form-check d-inline-block">
              <input
                class="form-check-input"
                type="radio"
                value="F"
                id="Female"
                name="gender"
                v-model="form.gender"
              />
              <label class="form-check-label" for="Female">{{
                $t("title.common.female")
              }}</label>
            </div>
          </div>
        </div>
        <!-- // Required -->

        <div class="my-5 border">
          <h6
            class="sub-title collapse-content mb-0 py-3"
            type="button"
            v-b-toggle.collapse-opt-info
          >
            {{ $t("text.common.optional") }}
          </h6>
          <b-collapse id="collapse-opt-info">
            <div class="row g-0 table-type header-col mx-3 mb-3">
              <div class="col-sm-4">
                <span class="">{{ $t("title.membership.first_name") }}</span>
              </div>
              <div class="col-sm-8">
                <input type="text" placeholder="" v-model="form.firstname" />
                <!-- <p class="mt-2 text-subscript">
                  {{ $t("text.main.sign_up4") }}
                </p> -->
              </div>

              <div class="col-sm-4">
                <span class="">{{ $t("title.common.email") }}</span>
              </div>
              <div class="col-sm-8">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.email"
                  />
                  <button
                    class="btn btn-primary btn-md icon-check"
                    type="button"
                    @click="availability_check(3, form.email)"
                  >
                    {{ $t("button.common.availability_check") }}
                  </button>
                </div>
              </div>

              <!-- <div class="col-sm-4">
                <span class="required">{{ $t("title.membership.last_name") }}</span>
              </div>
              <div class="col-sm-8">
                <input
                  type="text"
                  name="lastname"
                  placeholder=""
                  id="lastname"
                  v-model="form.lastname"
                />
                <p class="mt-2 text-subscript">
                  {{ $t("text.main.sign_up6") }}
                </p>
              </div> -->

              <!-- <div class="col-sm-4">
                <span class="required">{{ $t("title.support.country") }}</span>
              </div>

              <div class="col-sm-8">
                <div class="d-flex flex-column flex-sm-row">
                  <div class="flex-grow-1">
                    <select
                      name="countryno"
                      v-model="form.countryno"
                      @change="change_country"
                    >
                      <option value="0">{{ $t("title.common.choose") }}</option>
                      <option
                        v-for="(val, index) in country_list"
                        :value="val.countryno"
                        :key="index + 't'"
                      >
                        {{ val.countryname }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-sm-4">
                <span class="required">{{ $t("title.membership.state") }}</span>
              </div>

              <div class="col-sm-8">
                <div class="d-flex flex-column flex-sm-row">
                  <div class="flex-grow-1">
                    <select name="statesno" v-model="form.statesno">
                      <option value="">{{ $t("title.common.choose") }}</option>
                      <option
                        v-for="(val, index) in states_list"
                        :value="val.states_pk"
                        :key="index + 't'"
                      >
                        {{ val.statesdesc }}
                      </option>
                    </select>
                  </div>
                </div>
              </div> -->

              <!-- <div class="col-sm-4">
                <span class="required">{{ $t("title.membership.time_zone") }}</span>
              </div>

              <div class="col-sm-8">
                <div class="d-flex flex-column flex-sm-row">
                  <div class="flex-grow-1">
                    <select name="timezone" id="timezone" v-model="form.timezone">
                      <option value="0">{{ $t("title.common.choose") }}</option>
                      <option
                        v-for="(val, index) in timezone_list"
                        :value="val.timezonelist_pk"
                        :key="index + 't'"
                      >
                        {{ val.timezonelist_name }} (UTC
                        {{
                          val.timezonelist_offset >= 0
                            ? "+" + val.timezonelist_offset
                            : val.timezonelist_offset
                        }}:00)
                      </option>
                    </select>
                  </div>
                </div>
              </div> -->

              <div class="col-sm-4">
                <span class="">{{ $t("title.membership.handedness") }}</span>
              </div>
              <div class="col-sm-8">
                <div class="form-check d-inline-block me-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="R"
                    id="Right"
                    name="hand"
                    checked
                    v-model="form.hand"
                  />
                  <label class="form-check-label" for="Right">{{
                    $t("title.membership.right_hand")
                  }}</label>
                </div>
                <div class="form-check d-inline-block">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="L"
                    id="Left"
                    name="hand"
                    v-model="form.hand"
                  />
                  <label class="form-check-label" for="Left">{{
                    $t("title.membership.left_hand")
                  }}</label>
                </div>
              </div>

              <div class="col-sm-4">
                <span class="">{{ $t("title.membership.tee_height") }}</span>
              </div>
              <div class="col-sm-8">
                <select
                  name="teeheight"
                  id="teeheight"
                  v-model="form.teeheight"
                >
                  <option
                    v-for="(height, index) in teeheight_arr"
                    :selected="index === 0"
                    :key="index"
                    :value="height"
                  >
                    {{ height }}
                  </option>
                </select>
              </div>
              <div class="col-sm-4">
                <span class="">{{ $t("title.common.favorite_location") }}</span>
              </div>

              <div class="col-sm-8">
                <div class="d-flex flex-column flex-sm-row">
                  <div class="flex-grow-1">
                    <select v-model="form.shopno">
                      <option value="">{{ $t("title.common.choose") }}</option>
                      <option
                        v-for="(val, index) in prefershop_list"
                        :value="val.shopno"
                        :key="index + 't'"
                      >
                        {{ val.shopname }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <span class="">{{ $t("title.membership.setting") }}</span>
              </div>
              <div class="col-sm-8">
                <div class="row g-2">
                  <div class="col-sm-4">
                    <select
                      name="fieldunit"
                      id="fieldunit"
                      v-model="form.fieldunit"
                    >
                      <option value="" disabled selected>
                        {{ $t("filter.setting.fairway") }}
                      </option>
                      <option value="1">
                        {{ $t("filter.setting.meter") }}
                      </option>
                      <option value="2">{{ $t("filter.setting.yard") }}</option>
                      <option value="3">{{ $t("filter.setting.feet") }}</option>
                    </select>
                  </div>
                  <div class="col-sm-4">
                    <select
                      name="greenunit"
                      id="greenunit"
                      v-model="form.greenunit"
                    >
                      <option value="" disabled selected>
                        {{ $t("filter.setting.green") }}
                      </option>
                      <option value="1">
                        {{ $t("filter.setting.meter") }}
                      </option>
                      <option value="2">{{ $t("filter.setting.yard") }}</option>
                      <option value="3">{{ $t("filter.setting.feet") }}</option>
                    </select>
                  </div>
                  <div class="col-sm-4">
                    <select
                      name="speedunit"
                      id="speedunit"
                      v-model="form.speedunit"
                    >
                      <option value="" disabled selected>
                        {{ $t("filter.setting.speed") }}
                      </option>
                      <option value="1">{{ $t("filter.setting.km_h") }}</option>
                      <option value="2">
                        {{ $t("filter.setting.mile_h") }}
                      </option>
                      <option value="3">{{ $t("filter.setting.m_s") }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
        <!-- // Optional -->

        <div class="agree-box">
          <div class="form-check">
            <input
              type="checkbox"
              id="privacy"
              required
              class="form-check-input"
              v-model="form.privacy"
              :checked="form.privacy === '1'"
            />
            <label class="form-check-label" for="privacy">{{
              $t("text.main.sign_up7")
            }}</label>
          </div>
          <div class="form-check">
            <input
              type="checkbox"
              id="emailagree"
              class="form-check-input"
              v-model="form.emailagree"
              :checked="form.emailagree === '1'"
            />
            <label class="form-check-label" for="emailagree">{{
              $t("text.main.sign_up8")
            }}</label>
          </div>
          <div class="justify-content-center justify-content-sm-start mt-4">
            <vue-recaptcha
              :sitekey="RECAPTCHA_SITEKEY"
              :loadRecaptchaScript="true"
              @verify="verify"
            ></vue-recaptcha>
          </div>
        </div>

        <div class="btn-bottom-wrapper">
          <b-button
            type="submit"
            size="xl"
            variant="primary"
            aria-label="Sign up"
          >
            {{ $t("button.common.sign_up") }}
          </b-button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import ax from "@/api/auth";
import ax2 from "@/api/membership";
import _ from "lodash";
import location from "@/components/Location";
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "Signup",
  data() {
    return {
      form: {
        recaptcha_yn: "n",
        recaptcha_token: "",
        id: "",
        id_chk: "n",
        idoverlap: "0",
        password: "",
        password_re: "",
        email: "",
        email_chk: "n",
        emailoverlap: "0",
        nickname: "",
        nick_chk: "n",
        nicknameoverlap: "0",
        firstname: "",
        lastname: "",
        gender: "M",
        hand: "R",
        teeheight: 0,
        fieldunit: "",
        greenunit: "",
        speedunit: "",
        emailagree: 0,
        privacy: 0,
        timezone: 0,
        daylighttime: 0,
        countryno: "0",
        statesno: "",
        shopno: "",
        handphone: "",
        auth_number: "",
      },
      check_form: {
        authchk: true,
      },
      formValidation: {
        password:
          "At least 8 characters, including English and numbers, and up to 30 characters.",
      },
      teeheight_arr: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
      timezone_arr: [
        -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8,
        9, 10, 11,
      ],
      timezone_list: [],
      country_list: [],
      states_list: [],
      prefershop_list: [],
      RECAPTCHA_SITEKEY: process.env.VUE_APP_RECAPTCHA_SITEKEY,
    };
  },
  components: {
    location,
    VueRecaptcha,
  },
  methods: {
    verify(response) {
      this.form.recaptcha_token = response;
      this.form.recaptcha_yn = "y";
    },
    onSubmit(event) {
      event.preventDefault();
      if (this.form.daylighttime) this.form.daylighttime = 1;
      else this.form.daylighttime = 0;

      if (this.form.emailagree) this.form.emailagree = 1;
      else this.form.emailagree = 0;

      if (this.form.privacy) this.form.privacy = 1;
      else this.form.privacy = 0;

      if (this.form.id === "") {
        alert(
          this.$i18n.t("alert.common.field_enter", {
            field: this.$i18n.t("title.common.id"),
          })
        );
        return false;
      }
      // if (this.form.id_chk === "n") {
      //   alert(
      //     this.$i18n.t("alert.common.field_check", {
      //       field: this.$i18n.t("title.common.id"),
      //     })
      //   );
      //   this.form.idoverlap = "0";
      //   return false;
      // }
      // this.form.idoverlap = "1";

      if (this.form.password === "") {
        alert(
          this.$i18n.t("alert.common.field_enter", {
            field: this.$i18n.t("title.common.password"),
          })
        );
        return false;
      }

      if (this.form.password !== this.form.password_re) {
        alert(this.$i18n.t("alert.common.password_not_match"));
        return false;
      }

      // if (this.form.email === "") {
      //   alert(
      //     this.$i18n.t("alert.common.field_enter", {
      //       field: this.$i18n.t("title.common.email"),
      //     })
      //   );

      //   return false;
      // }
      // if (this.form.email_chk === "n") {
      //   alert(
      //     this.$i18n.t("alert.common.field_check", {
      //       field: this.$i18n.t("title.common.email"),
      //     })
      //   );

      //   this.form.emailoverlap = "0";
      //   return false;
      // }

      // if (this.form.firstname === "") {
      //   alert(
      //     this.$i18n.t("alert.common.field_enter", {
      //       field: this.$i18n.t("title.membership.first_name"),
      //     })
      //   );
      //   return false;
      // }

      // if (this.form.lastname === "") {
      //   alert(
      //     this.$i18n.t("alert.common.field_enter", {
      //       field: this.$i18n.t("title.membership.last_name"),
      //     })
      //   );
      //   return false;
      // }

      // this.form.emailoverlap = "1";

      if (this.form.nickname === "") {
        alert(
          this.$i18n.t("alert.common.field_enter", {
            field: this.$i18n.t("title.common.nickname"),
          })
        );
        return false;
      }
      // if (this.form.nick_chk === "n") {
      //   alert(
      //     this.$i18n.t("alert.common.field_check", {
      //       field: this.$i18n.t("title.common.nickname"),
      //     })
      //   );
      //   this.form.nicknameoverlap = "0";
      //   return false;
      // }
      // this.form.nicknameoverlap = "1";

      if (this.form.recaptcha_yn === "n") {
        alert(this.$i18n.t("alert.common.recaptcha"));
        return false;
      }

      ax.sign_up(this.form, (flag, code, message) => {
        if (flag) {
          if (code === 200) {
            alert(message);
            this.$router.push({ name: "Login" });
            return true;
          } else {
            alert(message);
            //alert('code가 200이 아님')
            return false;
          }
        } else {
          alert(message);
          return false;
        }
      });
    },
    change_country() {
      this.form.statesno = "";
      this.get_stateslist();
    },
    change_states() {
      // this.get_timezonelist();
    },
    get_prefershoplist() {
      ax2.get_prefershoplist((flag, data) => {
        if (flag) {
          this.prefershop_list = data.shoplist;
        } else {
          alert(data);
        }
      });
    },
    // get_timezonelist() {
    //   ax2.get_timezonelist((flag, data) => {
    //     if (flag) {
    //       this.timezone_list = data.timezonelist;
    //     } else {
    //       alert(data);
    //     }
    //   });
    // },
    get_stateslist() {
      ax2.get_stateslist(this.form.countryno, (flag, data) => {
        if (flag) {
          this.states_list = data.stateslist;
          console.log("states_list", this.states_list);
        } else {
          alert(data);
        }
      });
    },
    get_countrylist() {
      ax2.get_countrylist((flag, data) => {
        if (flag) {
          this.country_list = data.countrylist;
        } else {
          alert(data);
        }
      });
    },

    sms_send() {
      if (this.form.handphone === "") {
        alert(
          this.$i18n.t("alert.common.field_enter", {
            field: this.$i18n.t("title.common.handphone"),
          })
        );
        return false;
      }
      ax.sms_send(this.form, (flag, data) => {
        if (flag) {
          this.check_form.authchk = true;
          alert(data.message);
          return true;
        } else {
          alert(data.message);
          return false;
        }
      });
    },
    auth_number_chk() {
      if (this.form.auth_number === "") {
        alert(
          this.$i18n.t("alert.common.field_enter", {
            field: this.$i18n.t("title.membership.auth_number"),
          })
        );
        return false;
      }
      ax.auth_number_chk(this.form, (flag, data) => {
        alert(data.message);
        if (flag) {
          return true;
        } else {
          this.form.auth_number = "";
          return false;
        }
      });
    },
    availability_check(type, name) {
      if (name === "") {
        // alert('데이터를 넣어주세요')
        if (type === 1) {
          alert(
            this.$i18n.t("alert.common.field_enter", {
              field: this.$i18n.t("title.common.id"),
            })
          );
        } else if (type === 2) {
          alert(
            this.$i18n.t("alert.common.field_enter", {
              field: this.$i18n.t("title.common.nickname"),
            })
          );
        } else if (type === 3) {
          alert(
            this.$i18n.t("alert.common.field_enter", {
              field: this.$i18n.t("title.common.email"),
            })
          );
        }
        return false;
      }

      ax.availability_check(type, name, (flag, msg, code) => {
        if (flag) {
          if (code === 200) {
            if (type === 1) {
              this.form.id_chk = "y";
            } else if (type === 2) {
              this.form.nick_chk = "y";
            } else if (type === 3) {
              this.form.email_chk = "y";
            }
            alert(msg);
          }
        } else {
          alert(msg);
          return false;
        }
      });
    },
    availability_reset(type) {
      if (type === 1) {
        this.form.id_chk = "n";
      } else if (type === 2) {
        this.form.nick_chk = "n";
      } else if (type === 3) {
        this.form.email_chk = "n";
      }
    },
  },
  created() {
    this.get_countrylist();
    // this.get_timezonelist();
    this.get_prefershoplist();
  },
  watch: {
    "form.countryno"() {
      this.get_stateslist();
      console.log("변경!", this.form.countryno);
    },
    "form.password": {
      handler: function (val) {
        const idReg2 = /(?=.*\d)(?=.*[a-zA-ZS]).{8,}/; // 문자, 숫자 1개이상 포함, 8자리 이상
        if (!idReg2.test(val)) {
          this.formValidation.password = this.$i18n.t("text.main.sign_up2");
        } else {
          this.formValidation.password = this.$i18n.t(
            "text.main.can_use_password"
          );
        }
      },
    },
  },
};
</script>
