var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('location'),_c('div',{staticClass:"page mt-0 login"},[_c('div',{staticClass:"wrap"},[_c('b-form',{attrs:{"name":"login_frm"},on:{"submit":_vm.onSubmit}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.id),expression:"form.id"}],staticClass:"form-control form-control-lg",attrs:{"type":"text","placeholder":_vm.$t('text.common.field_enter', {
              field: _vm.$t('text.common.id'),
            }),"aria-label":_vm.$t('text.common.field_enter', {
              field: _vm.$t('title.common.id'),
            }),"name":"id","autocomplete":"off"},domProps:{"value":(_vm.form.id)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "id", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.pw),expression:"form.pw"}],staticClass:"form-control form-control-lg my-4",attrs:{"type":"password","placeholder":_vm.$t('text.common.field_enter', {
              field: _vm.$t('title.common.password'),
            }),"aria-label":_vm.$t('text.common.field_enter', {
              field: _vm.$t('title.common.password'),
            }),"name":"pw","autocomplete":"off"},domProps:{"value":(_vm.form.pw)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "pw", $event.target.value)}}}),_c('div',{staticClass:"row g-0 mb-4"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.remember_yn),expression:"form.remember_yn"}],staticClass:"form-check-input",attrs:{"type":"checkbox","value":"y","name":"remember_yn","id":"flexCheck","true-value":"y","false-value":"n"},domProps:{"checked":_vm.form.remember_yn === 'y',"checked":Array.isArray(_vm.form.remember_yn)?_vm._i(_vm.form.remember_yn,"y")>-1:_vm._q(_vm.form.remember_yn,"y")},on:{"change":function($event){var $$a=_vm.form.remember_yn,$$el=$event.target,$$c=$$el.checked?("y"):("n");if(Array.isArray($$a)){var $$v="y",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "remember_yn", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "remember_yn", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "remember_yn", $$c)}}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"flexCheck"}},[_vm._v(_vm._s(_vm.$t("button.common.remember_id")))])])]),_c('div',{staticClass:"col-6 text-end"},[_c('router-link',{attrs:{"to":"/Find_Id_Pwd"}},[_vm._v(_vm._s(_vm.$t("text.login.forgot_id_password")))])],1)]),_c('input',{staticClass:"btn btn-primary btn-lg w-100",attrs:{"type":"submit"},domProps:{"value":_vm.$t('button.common.login')}}),_c('hr'),_c('p',{staticClass:"text-subscript text-center fs-14"},[_c('span',{staticClass:"material-icons-outlined me-1"},[_vm._v("info")]),_c('span',[_vm._v(_vm._s(_vm.$t("text.login.sign_up_yet")))]),_c('router-link',{staticClass:"d-inline-block ms-1 text-green",attrs:{"to":{ name: 'SignUp' }}},[_vm._v(" "+_vm._s(_vm.$t("text.login.sign_up"))+" ")])],1)])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }